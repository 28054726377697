import  {formatAmount} from "@/utils/utils";

export  default {
  data(){
    return {
      baseImgUrl: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/'
    }
  },
  filters:{
    formatAmount(amount){
      return formatAmount(amount)
    }
  }
}
