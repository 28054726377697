<template>
  <div>
     <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="个人佣金" name="first"> <personalCommission v-if="activeName == 'first'" /> </el-tab-pane>
      <el-tab-pane label="员工佣金" name="second"> <employeeCommission v-if="activeName == 'second'" /> </el-tab-pane>
      <el-tab-pane label="提现列表" name="third"> <withdrawalList v-if="activeName == 'third'" /> </el-tab-pane>
      <el-tab-pane label="佣金设置" name="fourth"> <commissionSettings v-if="activeName == 'fourth'" /> </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import personalCommission from './components/personalCommission';
import employeeCommission from './components/employeeCommission';
import withdrawalList from './components/withdrawalList';
import commissionSettings from './components/commissionSettings';
  export default {
    name:'commissionManagement',
    components:{
      personalCommission,
      employeeCommission,
      withdrawalList,
      commissionSettings
    },
    data(){
      return{
        activeName:'first'
      }
    },
    methods:{
      handleClick(tab){
        const { name } = tab;
        this.activeName = name;
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>