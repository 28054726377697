<template>
  <div class="view pa24">
    <!-- <el-button type="primary">导出</el-button> -->
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
      marginTop='0px'
    >
      <template v-slot:table>
        <el-table-column prop="avatar" align="center" label="头像">
          <template slot-scope="scope">
              <img :src="scope.row.avatarUrl" v-if="scope.row.avatarUrl" class="  w50 h50"> 
              <img src='../../../assets/images/userImg.png' v-else class="  w50 h50"> 
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="姓名" />
        <el-table-column
          prop="phone"
          align="center"
          label="联系方式"
          show-overflow-tooltip
        />
        <el-table-column
          prop="successNum"
          align="center"
          label="总成交单数"
        />
        <el-table-column
          prop="orderAmount"
          align="center"
          label="总订单金额（元）"
        />
        <el-table-column
          prop="sumBalance"
          align="center"
          label="总佣金（元）"
        />
        <el-table-column
          prop="balance"
          align="center"
          label="可提现佣金（元）"
        />
        <el-table-column
          prop="withdrawingBalance"
          align="center"
          label="提现中佣金（元）"
        />
        <el-table-column
          prop="withdrawBalance"
          align="center"
          label="已提现佣金"
        />
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="detall_tap(scope.row.distributorAppId)">详情</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { queryCommissionRecord } from "@/api/financing";
export default {
  name: "personalCommission",
  components: {
    commonTable,
  },
  data() {
    return {
      tableData: [
        {
          avatar: "1",
          name: "王可可",
          contactDetails: "18683128612",
          transactions: "12",
          amount: "397397",
          totalCommission: "150.50",
          withdrawableCommission: "120",
          duringWithdrawal: "30.5",
          commissionWithdrawn: "0",
        },
        {
          avatar: "2",
          name: "唯你是青山",
          contactDetails: "18683128612",
          transactions: "2",
          amount: "397397",
          totalCommission: "1520.50",
          withdrawableCommission: "2120",
          duringWithdrawal: "30.25",
          commissionWithdrawn: "20",
        },
      ],
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      currentPage: 1, //当前页
    };
  },
  created() {
    this.searchPage();
  },
  methods: {
    searchPage() {
      this.loading = true;
      let data = {
        pageSize: 10,
        userType: 2,
        pageNum:this.pageNum,
        pageSize:this.pageSize
      };
      queryCommissionRecord(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list
          this.total = res.data.pageInfo.total
        })
        .catch((err) => {});
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.searchPage();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.currentPage = 1;
      this.searchPage();
    },
    detall_tap(id){
      this.$router.push({ path:`/personalCommissionDetall?id=${id}` });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>